import { createSlice } from '@reduxjs/toolkit';

// Types
import { ReduxState } from '../../core/types/ReduxStoreTypes';
import { StateInfo } from '../../core/types/CountryStateTypes';
import { CountryDataInfo } from '../../core/types/CountryDataTypes';
import { DocType } from '../../core/types/PoaPoiDocsTypes';
import { LocaleTypes } from '../../core/types/OrganizationTypes';
// Redux
import { initialState } from '../initialState';
// API Wrappers
import getCountryData from '../api/getCountryData';
import getCountryStateData from '../api/getCountryStateData';
import getCurrencyData from '../api/getCurrencyData';
import getEtaData from '../api/getEtaData';
import getProofOfIdentity from '../api/getProofOfIdentity';
import getProofOfAddress from '../api/getProofOfAddress';
import { countryFlagMapping } from '../../core/CountryMetadata/referenceData/CountryFlagMapping';
import getLocationLookup from '../api/getLocationLookup';
import getEmploymentStatusData from '../api/getEmploymentStatusData';
import getEmploymentIndustryData from '../api/getEmploymentIndustryData';
import getOccupationData from '../api/getOccupationData';
// Utils
import { getDialingCodeOptionsData } from '../../core/utils/DialingData';
import { getCurrencyDropdownOptions } from '../../core/utils/CurrencyData/GetCurrencyData';
import { getEtvDropdownOptions } from '../../core/utils/GetEtvData';
import { getPoiPoaDropdownOptions, getPoiPoaDocSides } from '../../core/utils/GetPoaAndPoiDocsData';

const referenceDataSlice = createSlice({
  name: 'referenceData',
  initialState: initialState.referenceData,
  reducers: {
    fetchCountryData(state, action) {
      state.countryData = action.payload;
    },
    setSelectedLanguage(state, action) {
      state.selectedLanguage = action.payload;
    }
  },
  extraReducers: (builder) => {
    //Get Country Data
    builder
      .addCase(getCountryData.pending, (state) => {
        state.countryLoading = 'loading';
      })
      .addCase(getCountryData.fulfilled, (state, { payload }) => {
        const flagData: { [name: string]: string } = countryFlagMapping;
        const countryData: CountryDataInfo[] =
          payload && payload.data && payload.data.length > 0
            ? payload.data.map((country: { code: string; name: string; displayName: string; isdCode: string }) => {
                const flagIcon: string = country?.code || '';

                return {
                  name: country.name,
                  label: country.displayName,
                  value: country.code,
                  isdCode: country.isdCode,
                  iconPath: flagData[flagIcon]
                };
              })
            : [];

        state.countryData = countryData;
        state.languageForCountryData = state.selectedLanguage as LocaleTypes;
        state.countryLoading = 'succeeded';
        state.dialingCodeData = getDialingCodeOptionsData(countryData);
      })
      .addCase(getCountryData.rejected, (state, action) => {
        state.countryLoading = 'failed';
        state.error = action.error.message;
      });

    //Get Country State Data
    builder
      .addCase(getCountryStateData.pending, (state) => {
        state.countryStateLoading = 'loading';
        // clearing state data while the API is being called
        state.countryStateData = {
          ...state.countryStateData,
          stateInfo: []
        };
      })
      .addCase(getCountryStateData.fulfilled, (state, { payload }) => {
        const countryStateData: StateInfo[] =
          payload && payload?.stateData?.data && payload.stateData.data.length > 0
            ? payload.stateData.data.map(
                (stateDetails: { code: string; displayName: string; cdLicenseStatus: string }) => {
                  return {
                    code: stateDetails.code,
                    displayName: stateDetails.displayName,
                    cdLicenseStatus: stateDetails.cdLicenseStatus
                  };
                }
              )
            : [];
        state.countryStateData = {
          countryCode: payload.countryCode,
          stateInfo: countryStateData
        };
        state.countryStateLoading = 'succeeded';
      })
      .addCase(getCountryStateData.rejected, (state, action) => {
        state.countryStateLoading = 'failed';
        state.error = action.error.message;
      });

    //Get Currency Data
    builder
      .addCase(getCurrencyData.pending, (state) => {
        state.currenciesLoading = 'loading';
      })
      .addCase(getCurrencyData.fulfilled, (state, { payload }) => {
        state.currenciesData = payload?.data;
        state.currenciesLoading = 'succeeded';
        state.currenciesOptionData = getCurrencyDropdownOptions(payload?.data);
      })
      .addCase(getCurrencyData.rejected, (state, action) => {
        state.currenciesLoading = 'failed';
        state.error = action.error.message;
      });

    //Get Location Lookup Data
    builder
      .addCase(getLocationLookup.pending, (state) => {
        state.locationLookupLoading = 'loading';
      })
      .addCase(getLocationLookup.fulfilled, (state, { payload }) => {
        state.locationLookupLoading = 'succeeded';
        state.ipBasedCountryCode = payload?.data?.countryCode;
      })
      .addCase(getLocationLookup.rejected, (state, action) => {
        state.locationLookupLoading = 'failed';
        state.error = action.error.message;
      });

    //Get estimated-transfer-amounts Data
    builder
      .addCase(getEtaData.pending, (state) => {
        state.etvLoading = 'loading';
      })
      .addCase(getEtaData.fulfilled, (state, { payload }) => {
        state.etvData = payload?.data;
        state.etvLoading = 'succeeded';
        state.etvOptionData = getEtvDropdownOptions(payload?.data);
      })
      .addCase(getEtaData.rejected, (state, action) => {
        state.etvLoading = 'failed';
        state.error = action.error.message;
      });

    //Get proof-of-identity Data
    builder
      .addCase(getProofOfIdentity.pending, (state) => {
        state.poiLoading = 'loading';
      })
      .addCase(getProofOfIdentity.fulfilled, (state, { payload }) => {
        state.poiData = payload?.data;
        state.poiDropdownOptions = getPoiPoaDropdownOptions(payload?.data);
        state.poiDocSides = getPoiPoaDocSides(payload?.data, DocType.poi);
        state.poiLoading = 'succeeded';
      })
      .addCase(getProofOfIdentity.rejected, (state, action) => {
        state.poiLoading = 'failed';
        state.error = action.error.message;
      });

    //Get proof-of-address Data
    builder
      .addCase(getProofOfAddress.pending, (state) => {
        state.poaLoading = 'loading';
      })
      .addCase(getProofOfAddress.fulfilled, (state, { payload }) => {
        state.poaData = payload?.data;
        state.poaDropdownOptions = getPoiPoaDropdownOptions(payload?.data);
        state.poaDocSides = getPoiPoaDocSides(payload?.data, DocType.poa);
        state.poaLoading = 'succeeded';
      })
      .addCase(getProofOfAddress.rejected, (state, action) => {
        state.poaLoading = 'failed';
        state.error = action.error.message;
      });

    //Get employment status data
    builder
      .addCase(getEmploymentStatusData.pending, (state) => {
        state.employmentStatusLoading = 'loading';
      })
      .addCase(getEmploymentStatusData.fulfilled, (state, { payload }) => {
        state.employmentStatusData = payload;
        state.employmentStatusLoading = 'succeeded';
      })
      .addCase(getEmploymentStatusData.rejected, (state, action) => {
        state.employmentStatusLoading = 'failed';
        state.error = action.error.message;
      });

    //Get employment industry data
    builder
      .addCase(getEmploymentIndustryData.pending, (state) => {
        state.employmentIndustryLoading = 'loading';
      })
      .addCase(getEmploymentIndustryData.fulfilled, (state, { payload }) => {
        state.employmentIndustryData = payload;
        state.employmentIndustryLoading = 'succeeded';
      })
      .addCase(getEmploymentIndustryData.rejected, (state, action) => {
        state.employmentIndustryLoading = 'failed';
        state.error = action.error.message;
      });

    // Get Occupation data
    builder
      .addCase(getOccupationData.pending, (state) => {
        state.occupationDataLoading = 'loading';
      })
      .addCase(getOccupationData.fulfilled, (state, { payload }) => {
        state.occupationData = payload;
        state.occupationDataLoading = 'succeeded';
      })
      .addCase(getOccupationData.rejected, (state, action) => {
        state.occupationDataLoading = 'failed';
        state.error = action.error.message;
      })
  }
});

export const getReferenceData = (state: ReduxState) => state.referenceData;

export const { fetchCountryData, setSelectedLanguage } = referenceDataSlice.actions;

export default referenceDataSlice.reducer;
