import { useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { SplitLayout } from '../../../components/organisms/SplitLayout';
import { RPCloseConfirmationModal } from '../../organisms/RPCloseConfirmationModal';
import { RPUserIdleDetection } from '../../organisms/RPUserIdleDetection';
import { RPOnfidoCloseConfirmationModal } from '../../organisms/RPOnfidoCloseConfirmationModal';
import FullLayout from '../../organisms/FullLayout';
import CreateLogin from './CreateLogin';
import SetupSecurity from './SetupSecurity';
import EnterDetails from './EnterDetails';
import SetPreferences from './SetPreferences';
import Identity from './Identity';
import { KYCFail } from './ConfirmingYou';
// Hooks
import { useAnimation, useStepParams } from '../../../core/hooks';
// Types
import { AppDispatch } from '../../../redux/store';
import { LayoutProps } from '../../../core/types/LayoutProps';
// RTK Slice
import { errorStateReset } from '../../../redux/modules/errorDetailsSlice';
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';
import { openLoginDialogModal } from '../../../redux/modules/loginDetailsSlice';
// Utils
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import checkNavigationFromLandingPage from '../../../core/utils/CheckNavigationFromLandingPage';
// Constants
import {
  INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST,
  KYC_PASS_PAGE_URL,
  ORGANIZATION_CODE_MAPPING,
  SKIP_DOCS_PAGE_URL,
  SKIP_DOCS_RETURN_PAGE_URL
} from '../../../core/utils/Constants/Constants';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Feature Flag
import { featureFlags } from '../../../core/FeatureFlags/FeatureFlags';

interface ContainerProps extends LayoutProps {}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'layout'
})<ContainerProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    flexDirection: 'column',
    ...(isMobileLayout && {
      paddingBottom: '88px'
    })
  };
});

const Registration = () => {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const [animateContent, setAnimateContent] = useAnimation(false);
  const cssTransitionRef = useRef(null);

  const { isOnfidoRequired } = useSelector(getRegistrationDetails);

  const {
    eventAction: { click },
    kycFailedDocument: {
      click: { skipDocumentDoItLater, skipDocumentUploadNowCTA }
    }
  } = GoogleAnalyticsTagsMapping;

  const {
    landingPage: { useReactLandingPage },
    onfido: { updateUploadDocLaterLink }
  } = featureFlags;

  // This useEffect will trigger on every page load and refresh to reset error state
  useEffectOnce(() => {
    dispatch(errorStateReset());
  });

  let { step, stepNumber } = useStepParams();

  let currentView: JSX.Element | null = null;

  let isRegistrationReturn: boolean = false;
  let isRegistrationReturnInactive: boolean = false;

  const shouldUpdateUploadDocLaterLink: boolean =
    updateUploadDocLaterLink &&
    INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST.includes(
      tenant || ORGANIZATION_CODE_MAPPING.currenciesdirect
    );

  switch (step) {
    case 'account':
      currentView = <CreateLogin />;
      break;
    case 'set-up-security':
      currentView = <SetupSecurity />;
      break;
    case 'your-details':
      currentView = <EnterDetails />;
      break;
    case 'set-preference':
      currentView = <SetPreferences />;
      break;
    case 'identity':
      currentView = <Identity />;
      break;
    case 'document-upload':
      currentView = <KYCFail />;
      break;
    case 'document-upload-return':
      currentView = <KYCFail isRegistrationReturn={true} />;
      isRegistrationReturn = true;
      break;
    case 'document-upload-return-inactive':
      currentView = <KYCFail isRegistrationReturnInactive={true} />;
      isRegistrationReturnInactive = true;
      break;
    default:
      navigate('/page-not-found');
  }

  const handlePrimaryBtnClick = () => {
    dispatch(openLoginDialogModal(false));
    TriggerGoogleAnalyticsTag(click, skipDocumentUploadNowCTA);
  };

  const navigateToKycPassPage = () => {
    navigate(KYC_PASS_PAGE_URL);
  };

  const navigateToDocsSkipped = () => {
    const navigateToURL: string = isRegistrationReturn ? SKIP_DOCS_RETURN_PAGE_URL : SKIP_DOCS_PAGE_URL;
    navigate(navigateToURL);
    TriggerGoogleAnalyticsTag(click, skipDocumentDoItLater);
  };

  const uploadDocLaterLinkHandler = () => {
    if (shouldUpdateUploadDocLaterLink) {
      navigateToKycPassPage();
    } else {
      navigateToDocsSkipped();
    }
  };

  const isFromLandingPage: boolean = !useReactLandingPage && checkNavigationFromLandingPage();

  const children: JSX.Element = (
    <>
      {isOnfidoRequired ? (
        <RPOnfidoCloseConfirmationModal
          navigateToDocsSkipped={uploadDocLaterLinkHandler}
          handlePrimaryBtnClick={handlePrimaryBtnClick}
        />
      ) : (
        <RPCloseConfirmationModal />
      )}
      {!isFromLandingPage && <RPUserIdleDetection isRegistrationReturnInactive={isRegistrationReturnInactive} />}

      <CSSTransition
        nodeRef={cssTransitionRef}
        in={animateContent}
        timeout={500}
        classNames="slideUp"
        unmountOnExit
        onEnter={() => setAnimateContent}
        onExited={() => setAnimateContent}
      >
        <div ref={cssTransitionRef}>
          <Container layout={layout} data-testid="form-container">
            <SwitchTransition mode="out-in">
              <CSSTransition key={stepNumber} timeout={500} classNames="slideUp">
                <div ref={cssTransitionRef}>{currentView}</div>
              </CSSTransition>
            </SwitchTransition>
          </Container>
        </div>
      </CSSTransition>
    </>
  );

  return currentView !== null ? (
    isRegistrationReturnInactive ? (
      <FullLayout
        layoutType="old"
        hideAdditionalInteractions
        stickHeaderAtTop={false}
        shouldAddWrapperContainerStyling={true}
      >
        {children}
      </FullLayout>
    ) : (
      <SplitLayout isAnimating={animateContent} layoutVariant={stepNumber === 1 ? 'full' : 'partial'}>
        {children}
      </SplitLayout>
    )
  ) : null;
};

export default Registration;
