import { FC, useContext, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPButton, { RPButtonContainer } from '../../../atoms/RPButton';
// API
import generateSDKToken, { GenerateSDKTokenData } from '../../../../redux/api/generateSDKToken';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
import {
  RegistrationReturnInactiveProps,
  RegistrationReturnProps
} from '../../../../core/types/RegistrationReturnTypes';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import GetBasicDetails from '../../../../core/utils/GetBasicDetails';
import { getIcon } from '../../../../core/utils/IconOrgData';
import IsMobileLayoutApplicable, { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// RTK Slice
import { getRegistrationDetails } from '../../../../redux/modules/registrationDetailsSlice';
// Constants
import { REGISTRATION_RETURN_INACTIVE_GENERIC_ERROR_PAGE } from '../../../../core/utils/Constants/Constants';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface VerifyIdentityContainerProps extends LayoutProps, RegistrationReturnInactiveProps {}
interface VerifyIdentityContentWrapperProps extends LayoutProps, RegistrationReturnInactiveProps {}
interface StyledHeadingProps extends LayoutProps {}
interface VerifyIdentityScreenProps extends RegistrationReturnProps, RegistrationReturnInactiveProps {}

const VerifyIdentityContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRegistrationReturnInactive'
})<VerifyIdentityContainerProps>(({ theme, layout, isRegistrationReturnInactive }) => {
  const {
    colours: {
      text: { textWeak },
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  const isMobileLayout = isMobileLayoutApplicable(layout);

  return {
    ...(isRegistrationReturnInactive && {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
      background: COLOUR_BG_BACKGROUND_ALT,
      marginBottom: '30px',
      gap: isMobileLayout ? '20px' : '32px',
      color: COLOUR_TEXT_TEXT_WEAK,
      boxSizing: 'border-box',
      position: 'relative',
      textAlign: 'center',
      width: isMobileLayout ? '325px' : '550px',
      ...(!isMobileLayout && {
        height: '100%'
      })
    })
  };
});

const VerifyIdentityContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRegistrationReturnInactive'
})<VerifyIdentityContentWrapperProps>(({ theme, layout, isRegistrationReturnInactive }) => {
  const isMobileLayout: boolean = IsMobileLayoutApplicable(layout);

  return {
    ...(isRegistrationReturnInactive && {
      width: '300px',
      margin: isMobileLayout ? '40px 0px' : '80px 0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& .MuiButton-root': {
        width: '100%'
      },
      '> svg': {
        marginBottom: '20px',
        ...(isMobileLayout && {
          width: '110px',
          height: '110px'
        })
      }
    })
  };
});

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ theme }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;

  return {
    marginBottom: '20px',
    color: COLOUR_TEXT_TEXT
  };
});

const StyledDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: '40px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const VerifyIdentityScreen: FC<VerifyIdentityScreenProps> = ({
  isRegistrationReturn = false,
  isRegistrationReturnInactive = false
}: VerifyIdentityScreenProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { parentOrganizationCode } = GetBasicDetails();
  const {
    crmAccountID,
    crmContactID,
    email,
    firstName,
    lastName,
    countryOfResidence,
    verifyIdentityBtnLoading,
    generateSDKTokenLoading
  } = useSelector(getRegistrationDetails);

  const VerifyIdIcon = useMemo(() => getIcon(tenant, 'verifyIdIcon'), [tenant]);

  const {
    eventAction: { load, click },
    kycFailedDocument: {
      click: { poiOnfidoCTAClicked, poiOnFidoCTAClickedReturn, poiOnFidoCTAClickedReturnInactive },
      load: { poiOnfidoLoaded, poiOnFidoLoadedReturn, poiOnFidoLoadedReturnInactive }
    }
  } = GoogleAnalyticsTagsMapping;

  const verifyIdentityHeading: string = translate('registration.confirmingItsYou.onFido.heading');
  const verifyIdentitySubHeading1: string = translate('registration.confirmingItsYou.onFido.subHeadingLine1');
  const verifyIdentitySubHeading2: string = translate('registration.confirmingItsYou.onFido.subHeadingLine2');
  const verifyIdentityButtonText: string = translate('registration.confirmingItsYou.onFido.buttonText');

  const inactiveVerifyIdentityHeading: string = translate(
    'registration.confirmingItsYou.inactiveRegistration.verifyIdentity.heading'
  );
  const inactiveVerifyIdentitySubHeading: string = translate(
    'registration.confirmingItsYou.inactiveRegistration.verifyIdentity.subHeading'
  );
  const inactiveVerifyIdentitySubmitButtonText: string = translate(
    'registration.confirmingItsYou.inactiveRegistration.verifyIdentity.submitButtonText'
  );

  const headingText: string = isRegistrationReturnInactive ? inactiveVerifyIdentityHeading : verifyIdentityHeading;
  const subHeadingText: string = isRegistrationReturnInactive
    ? inactiveVerifyIdentitySubHeading
    : verifyIdentitySubHeading1;
  const buttonText: string = isRegistrationReturnInactive
    ? inactiveVerifyIdentitySubmitButtonText
    : verifyIdentityButtonText;

  const headingVariant: 'h3' | 'h2' = isRegistrationReturnInactive ? 'h3' : 'h2';
  const subHeadingVariantType: 'normal' | 'medium' = isRegistrationReturnInactive ? 'normal' : 'medium';

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(
      load,
      isRegistrationReturn
        ? poiOnFidoLoadedReturn
        : isRegistrationReturnInactive
        ? poiOnFidoLoadedReturnInactive
        : poiOnfidoLoaded
    );
  });

  useEffect(() => {
    if (generateSDKTokenLoading === 'failed') {
      navigate(REGISTRATION_RETURN_INACTIVE_GENERIC_ERROR_PAGE);
    }
  }, [generateSDKTokenLoading]);

  const handleVerifyIdentityBtnClick = () => {
    TriggerGoogleAnalyticsTag(
      click,
      isRegistrationReturn
        ? poiOnFidoCTAClickedReturn
        : isRegistrationReturnInactive
        ? poiOnFidoCTAClickedReturnInactive
        : poiOnfidoCTAClicked
    );
    const generateSDKTokenData: GenerateSDKTokenData = {
      firstName,
      lastName,
      email,
      crmAccountID,
      crmContactID,
      parentOrganizationCode,
      countryOfResidence
    };

    dispatch(generateSDKToken(generateSDKTokenData));
  };

  const SubmitButton: JSX.Element = (
    <RPButton
      size={'large'}
      type="button"
      onClick={handleVerifyIdentityBtnClick}
      name="verifyIdentityButton"
      data-testid="rp-button-verify-identity"
      disabled={verifyIdentityBtnLoading}
      endIconType={verifyIdentityBtnLoading ? 'loader' : ''}
    >
      {buttonText}
    </RPButton>
  );

  return (
    <VerifyIdentityContainer layout={layout} isRegistrationReturnInactive={isRegistrationReturnInactive}>
      <VerifyIdentityContentWrapper layout={layout} isRegistrationReturnInactive={isRegistrationReturnInactive}>
        {isRegistrationReturnInactive && <VerifyIdIcon />}
        <StyledHeading variant={headingVariant} type="bold" text={headingText} layout={layout} />
        <StyledDescription variant={'body2'} type={subHeadingVariantType} text={subHeadingText} />
        {!isRegistrationReturnInactive && (
          <StyledDescription variant="body2" type={'bold'} text={verifyIdentitySubHeading2} />
        )}
        {isRegistrationReturnInactive ? (
          SubmitButton
        ) : (
          <RPButtonContainer layout={layout}>{SubmitButton}</RPButtonContainer>
        )}
      </VerifyIdentityContentWrapper>
    </VerifyIdentityContainer>
  );
};

export default VerifyIdentityScreen;
