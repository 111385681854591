import { FC, useContext } from 'react';
import { Control } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Contexts
import { LanguageContext, LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import { RPSelectNew } from '../../../atoms/RPSelect';
// Types
import { EnterDetailsType } from '../../../../core/types/RegistrationStepForm';
import { OptionType } from '../../../../core/types/SelectTypes';
// RTK Slice
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';

interface EmploymentOccupationProps {
  getFieldHeading: (labelText: string) => JSX.Element;
  control: Control<EnterDetailsType, any>;
  occupation: string;
  handleOccupationChange: (value: string) => void;
}

const {
  enterDetails: {
    occupation: { name: occupationFieldName }
  }
} = REGISTRATION_DEFINITIONS;

const EmploymentOccupation: FC<EmploymentOccupationProps> = (props: EmploymentOccupationProps) => {
  const {
    getFieldHeading,
    control,
    occupation,
    handleOccupationChange,
  } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { occupationData} = useSelector(getReferenceData);

  const employmentHeading = translate('registration.enterDetails.employment.heading');
  const occupationPlaceholder = translate('registration.enterDetails.employment.occupationPlaceholder');

  const occupationOptions: OptionType[] = occupationData.map((data) => {
    return {
      value: data.name,
      label: data.displayName
    };
  });

  const shouldShowMaxOptions: boolean = !isMobileLayoutApplicable(layout);

  return (
    <>
      {getFieldHeading(employmentHeading)}
      <ControllerElementWrapper
        name={occupationFieldName}
        control={control}
        defaultValue={occupation}
        options={occupationOptions}
        placeholderLabel={occupationPlaceholder}
        component={RPSelectNew}
        size="normal"
        handleChange={(value: string) => handleOccupationChange(value)}
        showMaxOptions={shouldShowMaxOptions}
        isMobileOptimised={true}
      />
    </>
  );
};

export default EmploymentOccupation;
