import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import FullLayout from '../../organisms/FullLayout';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// GA4
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';

interface KYCPassContainerProps extends LayoutProps {}

interface TextWrapperProps extends LayoutProps {}

const KYCPassContainer = styled(Box)<KYCPassContainerProps>(({ layout }) => {

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginTop: isDesktopLayout ? '46px' : isTabletLayout ? '22px' : '6px',
    gap: '30px',
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isMobileLayout ? '325px' : '550px',
    height: '100%',
    maxHeight: 600
  };
});

const TextWrapper = styled(Box)<TextWrapperProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text, textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: isDesktopLayout ? '20px' : isTabletLayout ? '15px' : '10px',
    '& .MuiTypography-root:nth-child(1)': {
      color: COLOUR_TEXT_TEXT,
      textAlign: 'center'
    },
    '& .MuiTypography-root:nth-child(2)': {
      color: COLOUR_TEXT_TEXT_WEAK,
      textAlign: 'center'
    }
  };
});

const RegistrationReturnInactiveKycPassPage = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const DocUploadedSVG = useMemo(() => getIcon(tenant, 'documentUploaded'), [tenant]);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const {
    eventAction: { load },
    stp: {
      load: { stpCompleteReturnInactive }
    }
  } = GoogleAnalyticsTagsMapping;

  const headerVariant: 'h2' | 'h3' = isMobileLayout ? 'h3' : 'h2';
  const bodyVariant: 'body1' | 'body2' = isMobileLayout ? 'body2' : 'body1';

  const headingText: string = translate('registrationReturnInactiveKycPassPage.heading');
  const subHeadingLine1Text: string = translate('registrationReturnInactiveKycPassPage.subHeadingLine1');

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, stpCompleteReturnInactive);
  });

  return (
    <FullLayout layoutType="old" stickHeaderAtTop={false} hideAdditionalInteractions>
      <KYCPassContainer layout={layout}>
        <DocUploadedSVG />
        <TextWrapper layout={layout}>
          <RPText variant={headerVariant} type="bold" text={headingText} />
          <RPText variant={bodyVariant} type="normal" text={subHeadingLine1Text} />
        </TextWrapper>
      </KYCPassContainer>
    </FullLayout>
  );
};

export default RegistrationReturnInactiveKycPassPage;
