import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

type getCustomerDetailsByEmailTokenReqData = {
  token: string;
};

const getCustomerDetailsByEmailToken = createAsyncThunk(
  '/registration-service/getCustomerDetailsByEmailToken',
  async (data: getCustomerDetailsByEmailTokenReqData, thunkAPI) => {
    const { token } = data;
    const dataToReplace: ObjectMapping = {
      token
    };

    try {
      const response: AxiosResponse<any, any> = await axiosWrapper(
        'getCustomerDetailsByEmailToken',
        'GET',
        thunkAPI,
        null,
        dataToReplace
      );

      return response && response.data.data;

      /*const response = {
        "status": "success",
        "message": "success",
        "data": {
          "crmAccountId": "001UD000002SvblYAC",
          "crmContactId": "003UD000003Lo9lYAC",
          "complianceStatusCode": "",
          "source": "NGOP",
          "orgCode": "Currencies Direct",
          "emailAddress": "pfx_uat_testuser_lbvlbcg@dispostable.com",
          "firstName": "Xfyuc",
          "lastName": "Zsjsd",
          "legalEntity": "CDLGB",
          "countryOfResidenceISOCode": "GBR"
        }
      };*/
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      window.open('/generic-error-return-inactive', '_self');
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default getCustomerDetailsByEmailToken;