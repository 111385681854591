import { FC, useContext } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Contexts
import { LanguageContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../../atoms/RPText';
import RPInput from '../../../../atoms/RPInput';
import ControllerElementWrapper from '../../../../organisms/ControllerWrapper';
import EmploymentFields from '../EmploymentFields';
import EmploymentOccupation from '../EmploymentOccupation';
// Types
import { EnterDetailsType } from '../../../../../core/types/RegistrationStepForm';
import { HexColour } from '../../../../../core/types/ThemeConfigTypes';
// RTK Slice
import { getRegistrationDetails } from '../../../../../redux/modules/registrationDetailsSlice';
// Utils
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
// Constants
import { REGISTRATION_DEFINITIONS } from '../../RegistrationDefinitions';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Feature Flag
import { featureFlags } from '../../../../../core/FeatureFlags/FeatureFlags';

interface ESPDetailsProps {
  control: Control<EnterDetailsType, any>;
  watch: UseFormWatch<EnterDetailsType>;
  getFieldHeading: (labelText: string) => JSX.Element;
  employmentStatus: string;
  handleEmploymentStatusChange: (value: string) => void;
  showEmploymentIndustryDropdown: boolean;
  employmentIndustry: string;
}

interface FormWrapperProps {
  shouldShowEmploymentFields: boolean;
  showEmploymentIndustryDropdown: boolean;
}

const Container = styled('div')(({ theme }) => {
  const {
    colours: {
      backgrounds: { background }
    }
  } = theme;

  return {
    backgroundColor: background,
    padding: '20px'
  };
});

const FormWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldShowEmploymentFields' && prop !== 'showEmploymentIndustryDropdown'
})<FormWrapperProps>(({ shouldShowEmploymentFields, showEmploymentIndustryDropdown }) => {
  return {
    ...(shouldShowEmploymentFields && {
      // Employment label
      '> p:nth-of-type(2)': {
        marginBottom: 15
      }
    }),

    '> div': {
      marginBottom: 15,

      ...(shouldShowEmploymentFields
        ? {
            // Employment status
            ':nth-of-type(1)': {
              marginBottom: showEmploymentIndustryDropdown ? 15 : 20
            },
            // Employment Industry
            ':nth-of-type(2)': {
              marginBottom: showEmploymentIndustryDropdown ? 20 : 0
            },

            // NIE/NIF tax ID number
            ':nth-of-type(3)': {
              marginBottom: 0
            }
          }
        : {
            // NIE/NIF tax ID number
            ':nth-of-type(2)': {
              marginBottom: 0
            }
          })
    }
  };
});

const CheckIdDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: '20px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const {
  enterDetails: { showEmploymentFields, showOccupationDropdown }
} = featureFlags;

const ESPDetails: FC<ESPDetailsProps> = (props: ESPDetailsProps) => {
  const {
    control,
    watch,
    getFieldHeading,
    employmentStatus,
    handleEmploymentStatusChange,
    showEmploymentIndustryDropdown,
    employmentIndustry
  } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { esp, occupation } = useSelector(getRegistrationDetails);

  const checkIdentityDesc: string = translate('registration.enterDetails.esp.checkIdentityDesc');
  const occupationLabel: string = translate('registration.enterDetails.esp.occupation.label');
  const nieNifTaxIdNumberLabel: string = translate('registration.enterDetails.esp.nieNifTaxIdNumber.label');

  const {
    enterDetails: {
      occupation: { name: occupationFieldName },
      esp: {
        nieNifTaxIdNumber: { name: nieNifTaxIdNumberFieldName }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  const { occupation: occupationValue } = watch();

  const {
    eventAction: { select },
    enterYourDetails: {
      select: { occupation: occupationGA }
    }
  } = GoogleAnalyticsTagsMapping;

  const handleOccupationBlur = () => {
    TriggerGoogleAnalyticsTag(select, occupationGA, {
      occupation: occupationValue
    });
  };

  const employmentSection: JSX.Element = showOccupationDropdown ? (
    <EmploymentOccupation
      getFieldHeading={getFieldHeading}
      control={control}
      occupation={occupation}
      handleOccupationChange={handleEmploymentStatusChange}
    />
  ) : !showEmploymentFields ? (
    <>
      {/* Occupation */}
      <ControllerElementWrapper
        name={occupationFieldName}
        control={control}
        defaultValue={occupation}
        placeholderLabel={occupationLabel}
        component={RPInput}
        handleBlur={handleOccupationBlur}
      />
    </>
  ) : (
    <EmploymentFields
      getFieldHeading={getFieldHeading}
      control={control}
      employmentStatus={employmentStatus}
      handleEmploymentStatusChange={handleEmploymentStatusChange}
      showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
      employmentIndustry={employmentIndustry}
    />
  );

  return (
    <Container>
      <FormWrapper
        shouldShowEmploymentFields={showEmploymentFields}
        showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
      >
        <CheckIdDescription variant="body2" type="normal" text={checkIdentityDesc} />

        {employmentSection}

        {/* NIE/NIF tax ID number */}
        <ControllerElementWrapper
          name={nieNifTaxIdNumberFieldName}
          control={control}
          defaultValue={esp?.nieNifTaxIdNumber || ''}
          placeholderLabel={nieNifTaxIdNumberLabel}
          component={RPInput}
          isCoralogixMasked={true}
        />
      </FormWrapper>
    </Container>
  );
};

export default ESPDetails;
