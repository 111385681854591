import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { OccupationDataType } from '../../core/types/OccupationDataTypes';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// API Wrappers
import axiosWrapper, { ObjectMapping } from './axiosWrapper';
// Utils
import GetBasicDetails from '../../core/utils/GetBasicDetails';
// import OccupationsMockData from '../../core/utils/MockData/OccupationsMockData.json';

type GetOccupationDataRequest = {
  locale: string;
};

const getOccupationData = createAsyncThunk(
  '/reference-data/occupation',
  async (data: GetOccupationDataRequest, thunkAPI) => {
    const { locale } = data;
    const { orgCodeID, localeId } = GetBasicDetails(locale);

    const dataToInsert: ObjectMapping = {
      orgCodeID: orgCodeID + '',
      localeId: localeId + ''
    };

    try {
      // return OccupationsMockData;

      const response = await axiosWrapper('getOccupationData', 'GET', thunkAPI, null, dataToInsert);

      // const response = OccupationsMockData;

      const formattedResponse: OccupationDataType[] = response.data.data.reduce(
        (occupationData: OccupationDataType[], currentObj: any) =>
          currentObj.isActive
            ? [
                ...occupationData,
                {
                  name: currentObj.name,
                  displayName: currentObj.displayName
                }
              ]
            : occupationData,
        []
      );
      return formattedResponse;
    } catch (error: any) {
      console.error('Error fetching occupation data:', error);
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default getOccupationData;
