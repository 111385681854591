import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPSessionModal } from '../../molecules/RPModals';
// RTK Slice
import { resetStoreToInitialState } from '../../../redux/actions/resetStore';
import { removePersistedStoreData } from '../../../redux/store';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { WebWorker } from '../../../core/utils/WebWorker';
import { getLandingPageURL, getLoginPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { RESET_STORE_TO_INITIAL_STATE } from '../../../redux/ActionTypeConstants';
import {
  USER_IDLE_DURATION_IN_MS,
  SESSION_EXPIRY_MODAL_TIMER_IN_MS,
  SESSION_EXPIRED_LS_KEY,
  SESSION_TIMER_MODAL_LS_KEY
} from '../../../core/utils/Constants/Constants';
import { RegistrationReturnInactiveProps } from '../../../core/types/RegistrationReturnTypes';

const timerWorker = new Worker(WebWorker);

interface RPUserIdleDetectionProps extends RegistrationReturnInactiveProps {}

const RPUserIdleDetection: FC<RPUserIdleDetectionProps> = ({ isRegistrationReturnInactive = false }) => {
  const { translations } = useContext(LanguageContext);
  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const translate = TranslateWrapper(translations);
  const dispatch: any = useDispatch();

  const landingPageURL: string = getLandingPageURL(tenant);
  const loginPageURL: string = getLoginPageURL(tenant);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const isSessionExpired: boolean = !!localStorage.getItem(SESSION_EXPIRED_LS_KEY);
  const isSessionExpiryModalTimer: boolean = !!localStorage.getItem(SESSION_TIMER_MODAL_LS_KEY);

  const [remainingTimeBeforeIdle, setRemainingTimeBeforeIdle] = useState<number>(SESSION_EXPIRY_MODAL_TIMER_IN_MS);
  const [isSessionTimerModalOpen, setIsSessionTimerModalOpen] = useState<boolean>(false);
  const [timerPercentage, setTimerPercentage] = useState<number>(100);
  const [timerValue, setTimerValue] = useState<number>(0);
  const [isSessionExpiredModalOpen, setIsSessionExpiredModalOpen] = useState(false);

  const sessionTimerModalHeading: string = translate('modal.sessionTimerModal.heading');
  const sessionTimerModalSubHeadingLine1: string = translate('modal.sessionTimerModal.subHeadingLine1');
  const sessionTimerModalSubHeadingLine2: string = translate('modal.sessionTimerModal.subHeadingLine2');
  const sessionTimerModalBtnLabel: string = translate('modal.sessionTimerModal.primaryBtnLabel');
  const sessionTimerModalCountDownText: string = translate('modal.sessionTimerModal.countDownText');

  const sessionExpiredModalHeading: string = translate('modal.sessionExpiredModal.heading');
  const sessionExpiredModalSubHeadingLine1: string = translate('modal.sessionExpiredModal.subHeadingLine1');
  const sessionExpiredModalBtnLabel: string = translate('modal.sessionExpiredModal.primaryBtnLabel');
  const sessionExpiredModalBtnLabel1: string = translate('modal.sessionExpiredModal.primaryBtnLabel1');

  const sessionExpiredModalPrimaryBtnText: string = isRegistrationReturnInactive
    ? sessionExpiredModalBtnLabel1
    : sessionExpiredModalBtnLabel;

  const onIdle = () => {
    if (!isSessionExpiredModalOpen) {
      setIsSessionTimerModalOpen(true);
      setRemainingTimeBeforeIdle(SESSION_EXPIRY_MODAL_TIMER_IN_MS);

      setTimerPercentage(100);
      pauseIdleTimer();

      startWebWorkerInterval();
    }
  };

  const { start: startIdleTimer, pause: pauseIdleTimer } = useIdleTimer({
    onIdle,
    timeout: USER_IDLE_DURATION_IN_MS,
    throttle: 500,
    stopOnIdle: true
  });

  const startWebWorkerInterval = () => {
    timerWorker.postMessage({ turn: 'on' });
  };

  const resetWebWorkerInterval = () => {
    timerWorker.postMessage({ turn: 'off' });
  };

  useEffect(() => {
    timerWorker.onmessage = () => {
      setRemainingTimeBeforeIdle((prev) => prev - 1000);
    };

    return () => {
      resetWebWorkerInterval();
    };
  }, []);

  useEffect(() => {
    if (remainingTimeBeforeIdle < 0) {
      resetWebWorkerInterval();

      setIsSessionTimerModalOpen(false);
      setIsSessionExpiredModalOpen(true);

      localStorage.setItem(SESSION_EXPIRED_LS_KEY, 'true');

      // Clear the session expiry modal timer key
      localStorage.removeItem(SESSION_TIMER_MODAL_LS_KEY);

      //Setting all store slices to initial state at once
      dispatch(resetStoreToInitialState(RESET_STORE_TO_INITIAL_STATE));
    } else {
      //value of percentage reduced at each interval will be added to 100 to reverse animation of timer
      setTimerPercentage(100 + (100 - Math.ceil((remainingTimeBeforeIdle / SESSION_EXPIRY_MODAL_TIMER_IN_MS) * 100)));
      setTimerValue(Math.ceil(remainingTimeBeforeIdle / 1000));
    }
  }, [remainingTimeBeforeIdle]);

  useEffect(() => {
    if (isSessionExpired) {
      // pause idle timer and show session expired modal
      pauseIdleTimer();
      setIsSessionTimerModalOpen(false);
      setIsSessionExpiredModalOpen(true);
    }
  }, [isSessionExpired]);

  const handleClose = () => {
    resetWebWorkerInterval();

    if (isSessionTimerModalOpen) {
      setIsSessionTimerModalOpen(false);

      // Clear the session expiry modal timer key
      localStorage.removeItem(SESSION_TIMER_MODAL_LS_KEY);

      startIdleTimer();
    } else if (isSessionExpiredModalOpen) {
      setIsSessionExpiredModalOpen(false);

      localStorage.removeItem(SESSION_EXPIRED_LS_KEY);
      localStorage.removeItem('accessToken');

      removePersistedStoreData();

      const navigationUrl: string = isRegistrationReturnInactive ? loginPageURL : landingPageURL;

      window.open(navigationUrl, '_self');
    }
  };

  // Check if the key is present in local storage to show the session expiry timer modal
  useEffect(() => {
    if (isSessionExpiryModalTimer) {
      setIsSessionTimerModalOpen(true);
      setRemainingTimeBeforeIdle(SESSION_EXPIRY_MODAL_TIMER_IN_MS);

      setTimerPercentage(100);
      pauseIdleTimer();

      startWebWorkerInterval();
    }
  }, [isSessionExpiryModalTimer]);

  return (
    <>
      <RPSessionModal
        isOpen={isSessionTimerModalOpen}
        heading={sessionTimerModalHeading}
        subHeadingLine1={sessionTimerModalSubHeadingLine1}
        subHeadingLine2={sessionTimerModalSubHeadingLine2}
        primaryBtnLabel={sessionTimerModalBtnLabel}
        countDownText={sessionTimerModalCountDownText}
        handleClose={() => undefined} // disabling backdrop click on popup
        handlePrimaryBtnClick={() => handleClose()}
        modalVariant="sessionTimer"
        size={isMobileLayout ? 'small' : 'normal'}
        percentage={timerPercentage}
        timerValue={timerValue.toString()}
      />
      <RPSessionModal
        isOpen={isSessionExpiredModalOpen}
        heading={sessionExpiredModalHeading}
        subHeadingLine1={sessionExpiredModalSubHeadingLine1}
        primaryBtnLabel={sessionExpiredModalPrimaryBtnText}
        handleClose={() => undefined} // disabling backdrop click on popup
        handlePrimaryBtnClick={() => handleClose()}
        modalVariant="sessionExpired"
        size={isMobileLayout ? 'small' : 'normal'}
      />
    </>
  );
};

export default RPUserIdleDetection;
